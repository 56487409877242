<template lang="pug">
div.container
  div(v-if="!event.name")
    loading-spinner(message="Loading event...")
  div(v-if="event.name")
    div.row.justify-content-center
      b-alert(v-if="oldEvent" variant="warning" show) <strong>Warning!</strong> This is a past event. Checking people in will mess historical data.
    div.row.justify-content-center.text-center
      h1 Welcome to {{event.name}} ({{event.date | moment("DD.MM.YYYY")}}) in {{event.location}}!
    div.row.justify-content-center.text-center
      h2 First time here? Sign yourself in!
    br/
    br/
    div.jumbotron
      h2 Quick Create & Check-in
      b-form
        b-form-row
          b-form-group.col-sm(label="First Name *" label-for="firstName")
            b-input(type="text" name="firstName" v-model="newVisitor.firstName" required)
          b-form-group.col-sm(label="Last Name *" label-for="lastName")
            b-input(type="text" name="lastName" v-model="newVisitor.lastName" required)
        b-form-row
          b-form-group.col-sm(label="Email *" label-for="email")
            b-input(type="email" name="email" v-model="newVisitor.email" required)
          b-form-group.col-sm(label="Organization" label-for="organization")
            b-input(type="text" name="organization" v-model="newVisitor.organization")
        b-form-row.justify-content-center
          b-button.mr-3(variant="success" @click="quickAddVisitor(newVisitor)" :disabled="!isFormValid") Add Visitor
          b-button(variant="warning" @click="resetQuickAddForm()") Cancel
</template>

<script>
import EventCheckin from '@/views/EventCheckin.vue'

export default {
  extends: EventCheckin
}
</script>
